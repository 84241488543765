<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='dataItem'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

                <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Kişisel Bilgiler</h3>

					<p class="text-lg">
                        Bu alanda personel kişisel bilgileri görüntülüyorsunuz. <br>
                        <br>
                        Personel bilgilerini güncellemek için güncelle butonuna tıklayın.
					</p>

				</Sidebar>

                <Button label='Güncelle' icon='pi pi-pencil' class='p-button-success mr-2 p-2 pl-3 pr-3' @click='editPers' />
                
				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Öğrenim Durumu</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.education }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Mezun Olduğu Kurum</label>
                        <div class='col-12 md:col-10'>
                           {{ dataItem.lastSchool }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Askerlik Durumu</label>
                        <div class='col-12 md:col-10'>
                           {{ dataItem.militaryStatus }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Medeni Hali</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.maritalStatus  }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Yabancı Dil</label>
                        <div class='col-12 md:col-10'>
                          {{ dataItem.foreignLanguage }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Çocuk Sayısı</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.childCount }}
                        </div>
                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import {
    getEducationList,
    getEmployee,
    getForeignLanguageList, getGenderList, getMaritalStatusList,
    getMilitaryStatusList, getProjectEmployee,
} from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    created() {
        this._employeeService = new EmployeeService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.fillDropDownLists()
    },
    data() {
        return {
            employeeId: 0,
            projectEmployeeId:0,
            dataItem: {
                id: '',
                name: '',
                surName: '',
                isActive: '',
                insertDate: '',
                identityNumber: '',
                birthday: '',
                gender: '',
                education: '',
                lastSchool: '',
                militaryStatus: '',
                maritalStatus: '',
                foreignLanguage: '',
                childCount: '',
                address: '',
                cityId: '',
                townId: '',
                gsm: '',
                email: '',
                emergencyPersonName: '',
                emergencyPersonPhone: '',
                employeePhoto: '',
            },
            educationList:[],
            foreignLanguages: [],
            maritalStatuses: [],
            militaryStatuses: [],
            genders: [],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        editPers () {
            this.$router.push({ name: 'editEmployeeInformation', params: { employeeId: this.employeeId } });
        },

        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._employeeService.updateEmployeePersonelInformation(this.employeeId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan güncelleme başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
                }
            } else {
                showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
            }

        },
        validateForm() {

            return true;
        },
        async fillDropDownLists() {
            this.educationList = await getEducationList();
            this.foreignLanguages = await getForeignLanguageList();
            this.militaryStatuses = await getMilitaryStatusList();
            this.maritalStatuses = await getMaritalStatusList();
            this.genders = await getGenderList();
        }
    },
};

</script>


<style scoped>

</style>
